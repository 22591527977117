import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import {
  Container,
  Typography,
} from '@material-ui/core';
import { Link } from 'gatsby';
import { createContent } from "../common/Theme"

const NotFoundPage = () => {
  const content = createContent();

  return (
    <Layout>
      <Seo title="404: Not found" />

      <Container classes={{root: content.content}} className='not-found_container'>
        <Typography variant='h1'>
          404: Not Found
        </Typography>

        <div className='not-found_sub'>
          <Typography variant='subtitle1'>
            You just hit a route that doesn&#39;t exist...
          </Typography>
          <Typography variant='subtitle1'>
            Go back to our <Link to='/'>homepage</Link>
          </Typography>
        </div>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
